import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import {
  ProductOptions,
  ProductOptionsList,
  Heading,
  Button,
  ParallaxBox,
  FieldError,
  ProductTemplateImage,
} from 'components';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { navigate, useLocation } from '@reach/router';
import CartContext from 'context/CartContext';
import { useFormikContext, Formik, Form, Field } from 'formik';
import { DisplayFormikState } from '../../helpers/DisplayFormikState';
import { Persist } from 'formik-persist';
import { quantityValues, motionBagImage } from '../../constants';
import { AnimatePresence, motion } from 'framer-motion';
import { isMobile, isDesktop } from 'react-device-detect';
import * as yup from 'yup';
import { nanoid } from 'nanoid';
import useInView from 'react-cool-inview';

export default function ProductTemplate({ data }) {
  const { updateLineItem, checkout } = React.useContext(CartContext);
  const image = getImage(data.contentfulBags.image?.gatsbyImageData);
  const { search, origin, pathname } = useLocation();
  let variation = {};
  let searchParams = new URLSearchParams(search);

  const initialValues = {
    contentful_id: data.contentfulBags.contentful_id,
    title: data.contentfulBags.title,
    slug: data.contentfulBags.slug,
    wallpaper: searchParams.get('wallpaper')
      ? searchParams.get('wallpaper')
      : '',
    size: searchParams.get('size') ? searchParams.get('size') : '',
    material: searchParams.get('material') ? searchParams.get('material') : '',
    accesories: searchParams.get('accesories')
      ? searchParams.get('accesories')
      : '',
    quantity: searchParams.get('quantity') ? searchParams.get('quantity') : '',
  };

  /**
   * On Page load
   */
  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      typeof window !== 'undefined' &&
        window.gtag('event', 'view_item', {
          currency: 'USD',
          value: 0,
          items: [
            {
              item_id: data.contentfulBags.slug,
              item_name: data.contentfulBags.title,
              item_brand: 'SB5D',
            },
          ],
        });
    }
  });

  /**
   * Handles the form validation
   *
   * @returns validation
   */
  const schema = yup.object().shape({
    size: yup.string().required('Required'),
    material: yup.string().required('Required'),
    quantity: yup.string().required('Required'),
  });

  /**
   * Updates on form change
   *
   * @returns null
   */
  const AutoUpdateOnChange = () => {
    const { isValid, isSubmitting } = useFormikContext();

    return null;
  };

  /**
   * Handles back button
   * @returns void
   */
  const handleBack = () => {
    navigate('/store/');
  };

  /**
   * Handles form submit
   *
   * @param {string} values
   * @return void
   */
  const handleSubmit = (values) => {
    const uid = nanoid();
    let product = [];

    variation.id = uid;
    variation.title = data.contentfulBags.title;
    variation.slug = data.contentfulBags.slug;
    variation.image = data.contentfulBags.image?.gatsbyImageData;
    variation.options = values;
    delete variation.options['title'];
    delete variation.options['slug'];

    if (checkout) {
      product = [...product, ...checkout];
    }

    product.push(variation);
    updateLineItem(product);

    if (process.env.NODE_ENV === 'production') {
      typeof window !== 'undefined' &&
        window.gtag('event', 'add_to_cart', {
          currency: 'USD',
          value: 0,
          items: [
            {
              item_id: data.contentfulBags.slug,
              item_name: data.contentfulBags.title,
              item_brand: 'SB5D',
              item_category: variation.options['material'],
              item_variant: variation.options['quantity'],
              quantity: variation.options['size'],
            },
          ],
        });
    }
  };

  const { observe, inView } = useInView({
    // For better UX, we can grow the root margin so the image will be loaded before it comes to the viewport
    rootMargin: '100px',
  });

  return (
    <Layout
      pageTitle={
        data.contentfulBags.title.charAt(0).toUpperCase() +
        data.contentfulBags.title.toLowerCase().slice(1)
      }
      inViewHeading={inView}
    >
      <div id="bg-image" className="fixed -z-1 top-0 transform-gpu">
        <StaticImage
          src="../../images/Shooping-cart@2x.jpg"
          alt="SB5D"
          placeholder="blurred"
          layout="fixed"
          formats={['AUTO', 'WEBP', 'AVIF']}
        />
      </div>
      <main className="container lg:max-w-4xl relative z-2 lg:pt-10 lg:pb-20">
        <section>
          <div className="lg:mb-24 text-center" ref={observe}>
            <Heading>{data.contentfulBags.title}</Heading>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data.contentfulBags.description?.childMarkdownRemark.html,
              }}
            ></div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-8">
            <aside className="text-center">
              <ProductTemplateImage
                title={data.contentfulBags?.title}
                image={image}
                inViewHeading={inView}
              />
            </aside>
            <div>
              <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    handleSubmit(values);
                    setSubmitting(false);
                  }, 100);
                }}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    isValid,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    submitForm,
                    setFieldValue,
                    setValues,
                  } = props;
                  return (
                    <Form key="product-select">
                      <ProductOptionsList
                        errors={errors}
                        touched={touched}
                        content={data.contentfulBags}
                      />
                      <div className="grid grid-cols-2 gap-4">
                        <Button
                          onClick={handleBack}
                          className="btn-pink-outline"
                        >
                          Back
                        </Button>
                        <Button
                          type="submit"
                          onClick={submitForm}
                          isSubmitting={isSubmitting}
                        >
                          Next
                        </Button>
                      </div>
                      {/* {!isSubmitting && (
                        <Persist
                          name={`sb5d.product.${data.contentfulBags.slug}`}
                        />
                      )} */}
                      {/* <AutoUpdateOnChange /> */}
                      {/* <DisplayFormikState {...props} /> */}
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}

ProductTemplate.propTypes = {
  data: PropTypes.shape({
    contentfulBags: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

export const query = graphql`
  query BagsQuery($slug: String) {
    contentfulBags(slug: { eq: $slug }) {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      slug
      size
      material
      valve
      accessories
      wallpaper
      quantity
      image {
        gatsbyImageData
      }
    }
  }
`;
